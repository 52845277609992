<template>
  <div>
    <div id="main">
      <div class="container">
        <div class="main-item position">
          <a @click="goToView('/home/index')">首页</a>
          &gt;
          <span>活动预告</span>
        </div>
        <!--活动预告-->
        <div class="main-item">
          <div class="activityclass">
            <div class="box">
              <div class="title">
                <span><i></i>活动预告</span>
              </div>
              <a-list
                item-layout="vertical"
                :data-source="dataResource"
                :pagination="{
                  current: pagination.onpage,
                  pageSize: pagination.pageSize,
                  total: pagination.total,
                  showSizeChanger: false,
                  showLessItems: true,
                  showQuickJumper: true,
                  onChange: onChange,
                  onShowSizeChange: onSizeChange,
                }"
                class="activitylist"
              >
                <!-- <div slot="header">共搜索到{{ pagination.total }}条记录</div> -->
                <a-list-item
                  slot="renderItem"
                  :key="item.doi"
                  slot-scope="item"
                  class="list"
                >
                    <a class=".list-a" @click="goDetail('newsDetail',item.doi)" :title="item.title" v-html="item.name"></a>
                    <span>{{ item.pubdate }}</span>
                 
                </a-list-item>
              </a-list>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import * as Api from "@/services/activity";
import { replaceNew,silceTime,isMobile } from "@/utils/util";
export default {
  name: "Activity",
  components: {},
  props: {
    value: {
      type: String,
    },
  },
  data() {
    return {
      currentPage: ["Activity"],
      pagination: {
        page: 1,
        pageSize: 9,
        pagecount: 0, //资源总页数
        total: 0, //资源总数
        onpage: 1, //每次翻页后，列表组件回返回当前页面并更新此变量，重新请求
        keyword: "",
        classid: "",
        type: "p",
        typeid: 0,
      },
      dataResource: [],
    };
  },
  watch:{
    // value: function (val) {
    //   this.pagination.keyword = val;
    //   this.pagination.page = 1;
    //   this.pagination.pagecount = 0;
    //   this.pagination.total = 0;
    //   this.pagination.onpage = 1;
    //   this.dataResource = [];
    //   this.initResources()
    // },
    'value':{
     handler  (val) {
      this.pagination.keyword = val;
      this.pagination.page = 1;
      this.pagination.pagecount = 0;
      this.pagination.total = 0;
      this.pagination.onpage = 1;
      this.dataResource = [];
      this.initResources(this.selectedtype);
    },
    deep: true,
	      immediate: true,
    },
  },
  created() {
    this.Mobile()
    this.initResources()
  },
  methods: {
    Mobile(){
      if(isMobile()){
        this.$router.push({ name: 'mobileNews' });
      }
    },
    goToView(path) {
     this.$router.push({ path: path });
    },
    goDetail (name,doi) {
			this.$router.push({ name: name, params: { doi: doi,type:0 } })
		},
    onOpenChange(openKeys) {
      const latestOpenKey = openKeys.find(
        (key) => this.openKeys.indexOf(key) === -1
      );
      if (this.rootSubmenuKeys.indexOf(latestOpenKey) === -1) {
        this.openKeys = openKeys;
      } else {
        this.openKeys = latestOpenKey ? [latestOpenKey] : [];
      }
    },
    onSizeChange(current, size) {
      this.pagination.page = 1;
      this.pagination.onpage = 1;
      this.pagination.pageSize = size;
      //this.listrequest(1)
    },
    onChange(pageNumber, pageSize) {
      // 翻页时触发,分页组件绑定页数需求number类型
      this.pagination.onpage = pageNumber;
      this.pagination.page = pageNumber;
      this.pagination.pageSize = pageSize;
      this.initPicList();
    },
    initResources() {
        Api.getNewsList(this.pagination).then(res => {
				const { success } = res
				if (success) {
					const { data: { list, count } } = res
					this.dataResource = list.map(element => {
						return {
							name: replaceNew(element.title, this.pagination.keyword),
							title: element.title,
							pubdate: silceTime(element.pubdate),
							doi: element.urlid
						}
					})
					this.pagination.total = count
				} else {
					this.dataResource = []
					this.pagination.total = 0
				}
			})
    } 
  },
};
</script>
<style lang="less">
@import "~@/assets/css/list-activity.less";
</style>
 <style scoped>
 /* #main {
  margin-top: -57px;
} */
.activityclass >>> li.list a:hover {
  color: #d4000e;
}
/* .ant-list >>> .ant-list-pagination {
  padding: 20px 0;
  text-align: center;
  margin-top: 0;
}
.ant-list >>> .ant-pagination-item-active {
  background: #d4000e;
  color: #fff;
  border-color: #d4000e;
}
.ant-list >>>  .ant-pagination-item-active >>> a {
  color: #fff;
}
.ant-list >>> .ant-pagination-prev,
.ant-list >>> .ant-pagination-next,
.ant-list >>> .ant-pagination-jump-prev,
.ant-list >>> .ant-pagination-jump-next {
  height: 36px;
  min-width: 36px;
}

.ant-list >>> .ant-pagination-item {
  height: 36px;
  min-width: 36px;
  line-height: 35px;
}
.ant-list >>> .ant-pagination-options-quick-jumper {
  height: 36px;
}
.ant-list >>> .ant-pagination-options-quick-jumper >>> input {
  height: 36px;
  vertical-align: baseline;
} */
</style>