<template>
  <div>
    <div id="main">
      <div class="banner">
        <img src="~@/assets/images/banner-reading.jpg" alt="" />
      </div>
      <div class="container">
        <div class="main-item clearfix book">
          <div class="left-nav fl">
            <h4><a @click="changeTheme">分类导航</a></h4>
            <ul>
              <li
                v-for="item in themeList"
                :key="item.themeid"
                :class="item.class"
              >
                <a
                  href="javascript:;"
                  :class="item.class"
                  @click="clicktheme(item)"
                  >{{ item.themename }}<i></i
                ></a>
              </li>
              <!-- <li>
                <a href="#">新时代中国特色社会主义理论核心价值观<i></i></a>
              </li>
              <li>
                <a href="#">乡村振兴<i></i></a>
              </li>
              <li>
                <a href="#">传统文化<i></i></a>
              </li>
              <li>
                <a href="#">法律政策<i></i></a>
              </li>
              <li>
                <a href="#">悦慧生活（健康养生、营养美食）<i></i></a>
              </li>
              <li>
                <a href="#">成长阅读<i></i></a>
              </li>
              <li>
                <a href="#">知识通览<i></i></a>
              </li> -->
            </ul>
            <!-- <a-menu
              mode="inline"
              :open-keys="openKeys"
              style="width: 260px"
              @openChange="onOpenChange"
            >
              <template v-for="theme in themeList">
                <a-sub-menu
                  :key="theme.themeid"
                  class="one"
                  @titleClick="onSelect"
                >
                  <span slot="title"
                    ><span :title="theme.themename">{{
                      theme.themename
                    }}</span></span
                  >
                  <template v-for="themetwo in theme.childlist">
                    <a-menu-item
                      v-if="themetwo.childlist == null"
                      :key="themetwo.themeid"
                      class="two"
                      @click="onSelect"
                      @deselect="deselect"
                      style="margin-top: 0px; margin-bottom: 0px"
                      >{{ themetwo.themename }}</a-menu-item
                    >
                    <a-sub-menu
                      v-else
                      :key="themetwo.themeid"
                      class="two"
                      @titleClick="onSelect"
                    >
                      <span slot="title"
                        ><span :title="themetwo.themename">{{
                          themetwo.themename
                        }}</span></span
                      >
                      <template v-for="themethree in themetwo.childlist">
                        <a-menu-item
                          :key="themethree.themeid"
                          class="three"
                          @click="onSelect"
                          style="margin-top: 0px; margin-bottom: 0px"
                          >{{ themetwo.themename }}</a-menu-item
                        >
                      </template>
                    </a-sub-menu>
                  </template>
                </a-sub-menu>
              </template>
            </a-menu> -->
          </div>
          <div class="right-con fr">
            <div class="box">
              <div class="title">
                <span><i></i>传统文化</span>
              </div>
              <div class="tab">
                <!-- <a  data-i="1" @click="selectresource('book')" :class="this.selectedtype=='book'?'selected':''">图书</a>
                <a  data-i="2" @click="selectresource('audio')" :class="this.selectedtype=='audio'?'selected':''">听书</a>
                <a  class="" data-i="3" @click="selectresource('video')" :class="this.selectedtype=='video'?'selected':''">视频</a> -->
                <a
                  href="javascript:;"
                  v-for="(item, index) in TypeList"
                  :class="item.class"
                  :key="index"
                  @click="TypeClick(item)"
                  >{{ item.name }}</a
                >
                <span
                  >共<b>{{ pagination.total }}</b
                  >个{{
                    selectedtype == "book"
                      ? "图书"
                      : selectedtype == "audio"
                      ? "听书"
                      : "视频"
                  }}</span
                >
              </div>
              <!-- <div class="res-list res-list-1"></div>
              <div class="res-list res-list-2"></div>
              <div class="res-list res-list-3 show"> -->
              <div class="res-list res-list-3">
                <a-list
                  :grid="{ gutter: 30, column: 3 }"
                  item-layout="vertical"
                  :data-source="dataResource"
                  :pagination="{
                    current: pagination.onpage,
                    pageSize: pagination.pageSize,
                    total: pagination.total,
                    showSizeChanger: false,
                    showLessItems: true,
                    showQuickJumper: true,
                    onChange: onChange,
                    onShowSizeChange: onSizeChange,
                  }"
                >
                  <a-list-item
                    slot="renderItem"
                    :key="item.doi"
                    slot-scope="item"
                    v-if="selectedtype == 'book'"
                    class="book"
                    :title="item.title"
                    @click="goDetail('bookDetail', item.doi)"
                  >
                    <a-card hoverable style="width: 190px">
                      <img slot="cover" alt="example" :src="item.imgurl" />
                      <!-- <a slot="cover" class="icon-play"><img src="~@/assets/images/btn-play.png"/></a> -->
                      <!-- <span slot="cover"></span> -->
                      <!-- <a-card-meta :title="item.name">
                        <template slot="description">
                          <p>{{ item.issuedep }}</p>
                        </template>
                      </a-card-meta> -->
                      <a-card-meta>
                        <a
                          slot="title"
                          :title="item.title"
                          href="javascript:;"
                          @click="goDetail('bookDetail', item.doi)"
                          v-html="item.name"
                        ></a>
                        <div slot="description">
                          <p>
                            {{ item.issuedep }}
                          </p>
                        </div>
                      </a-card-meta>
                    </a-card>
                  </a-list-item>
                  <a-list-item
                    slot="renderItem"
                    :key="item.doi"
                    slot-scope="item"
                    v-else
                    class="pic"
                    :title="item.title"
                    @click="
                      goDetail(
                        selectedtype == 'audio' ? 'AudioDetail' : 'videoDetail',
                        item.doi
                      )
                    "
                  >
                    <a-card hoverable style="width: 260px" class="pic">
                      <img slot="cover" alt="example" :src="item.imgurl" />
                      <a
                        slot="cover"
                        class="icon-play"
                        v-if="selectedtype === 'video'"
                        ><img src="~@/assets/images/icon-play.png"
                      /></a>
                      <span slot="cover" v-if="selectedtype === 'audio'"></span>
                      <!-- <a-card-meta :title="item.name">
                        <template slot="description">
                          <p>{{ item.source }}</p>
                        </template>
                      </a-card-meta> -->
                      <a-card-meta>
                        <a
                          slot="title"
                          :title="item.title"
                          href="javascript:;"
                          @click="
                            goDetail(
                              selectedtype == 'audio'
                                ? 'AudioDetail'
                                : 'videoDetail',
                              item.doi
                            )
                          "
                          v-html="item.name"
                        ></a>
                        <div slot="description">
                          <p>
                            {{ item.issuedep }}
                          </p>
                        </div>
                      </a-card-meta>
                    </a-card>
                  </a-list-item>
                </a-list>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import * as Api from "@/services/readings";
import { replacePath, replaceNew, isMobile } from "@/utils/util";
const orderTheme = (list) => {
  return list.sort((a, b) => a.ordernum - b.ordernum);
};
import { mapGetters, mapMutations } from "vuex";
export default {
  name: "Book",
  components: {},
  props: {
    value: {
      type: String,
    },
  },
  data() {
    return {
      rootSubmenuKeys: [],
      openKeys: [],
      themeList: [],
      currentPage: ["Book"],
      pagination: {
        page: 1,
        pageSize: 12,
        pagecount: 1, //资源总页数
        total: 0, //资源总数
        onpage: 1, //每次翻页后，列表组件回返回当前页面并更新此变量，重新请求
        keyword: "",
        classid: "",
        type: "p",
      },
      selectedtype: "book",
      dataResource: [],
      TypeList: [
        {
          name: "图书",
          type: "book",
          class: "selected",
        },
        {
          name: "听书",
          type: "audio",
          class: "",
        },
        {
          name: "视频",
          type: "video",
          class: "",
        },
      ],
    };
  },
  watch: {
    openKeys(val) {
      console.log("openKeys", val);
    },
    value: {
      handler(val) {
        this.pagination.keyword = val;
        this.pagination.page = 1;
        this.pagination.pagecount = 0;
        this.pagination.total = 0;
        this.pagination.onpage = 1;
        this.dataResource = [];
        this.initResources(this.selectedtype);
      },
      deep: true,
      immediate: true,
    },
  },
  computed: {
    // swiper() {
    //   return this.$refs.mySwiper.swiper;
    // },
    ...mapGetters("account", ["user"]),
  },
  created() {
    this.Mobile();
    this.initTheme("0");
    this.initResources(this.selectedtype);
  },
  methods: {
    Mobile() {
      if (isMobile()) {
        this.$router.push({ name: "mobileSearch" });
      }
    },
    ...mapMutations("account", ["saveNowRouter"]),
    goDetail(name, doi) {
      if (name === "AudioDetail" || name === "videoDetail") {
        var vm = this;
        if (!this.user || this.user === "") {
          this.$confirm({
            title: "确认提示",
            content: `登录后即可查看，是否确认登录？`,
            okType: "warning",
            onOk() {
              vm.saveNowRouter(vm.$route);
              vm.$router.push({ name: "Login" });
            },
            onCancel() {
              console.log("取消");
            },
          });
          return;
        }
        if (
          localStorage.getItem("expireAt") &&
          new Date().getTime() >=
            new Date(localStorage.getItem("expireAt")).getTime()
        ) {
          this.$confirm({
            title: "确认提示",
            content: `登录已过期，该操作需重新登录，是否重新登录？`,
            okType: "warning",
            onOk() {
              vm.saveNowRouter(vm.$route);
              vm.$router.push({ name: "Login" });
            },
            onCancel() {
              console.log("取消");
            },
          });
          return;
        }
      }
      this.$router.push({ name: name, params: { doi: doi } });
    },
    clicktheme(item) {
      this.themeList.forEach((element) => {
        element.class = "";
      });
      item.class = "active";
      this.pagination.classid = item.themeid;
      this.initResources(this.selectedtype);
    },
    initTheme(pid) {
      Api.getThemeList(pid).then((res) => {
        let {
          success,
          data: { list },
        } = res;
        if (success) {
          this.themeList = orderTheme(list);
          this.themeList.unshift({
            childlist: [],
            ordernum: 1,
            parentid: 0,
            themeid: "",
            themename: "全部",
          });
          // this.themeList=orderTheme(list).map(this.convertToTreeData);
          // this.themeList.unshift({
          //   childlist: [],
          //   ordernum: 1,
          //   parentid: 0,
          //   themeid: "",
          //   themename: "全部",
          // });
          this.themeList = this.themeList.map((e) => {
            return {
              themeid: e.themeid,
              themename: e.themename,
              parentid: e.parentid,
              class: e.themename === "全部" ? "active" : "",
            };
          });
        } else {
          this.themeList = [];
        }
      });
    },
    convertToTreeData(item) {
      const isLeaf =
        !Array.isArray(item.childlist) || item.childlist.length === 0;
      return {
        themeid: item.themeid,
        key: item.themeid,
        themename: item.themename,
        parentid: item.parentid,
        children: isLeaf
          ? []
          : orderTheme(item.childlist).map(this.convertToTreeData),
      };
    },
    changeTheme() {
      this.openKeys = [];
      this.pagination.classid = "";
      this.initResources(this.selectedtype);
    },
    onSelect(e) {
      this.pagination.classid = e.key;
      this.initResources(this.selectedtype);
    },
    deselect(e) {
      console.log(e);
    },
    TypeClick(item) {
      this.TypeList.forEach((element) => {
        element.class = "";
      });
      item.class = "selected";
      this.pagination.page = 1;
      this.pagination.pageSize = item.type == "book" ? 12 : 9;
      this.pagination.pagecount = 0;
      this.pagination.total = 0;
      this.pagination.onpage = 1;
      this.dataResource = [];
      this.initResources(item.type);
    },
    initResources(type) {
      this.selectedtype = type;
      if (type == "book") {
        Api.getBookList(this.pagination).then((res) => {
          const { success } = res;
          if (success) {
            const {
              data: { list, count },
            } = res;
            this.dataResource = list.map((element) => {
              return {
                name: replaceNew(element.name, this.pagination.keyword),
                title: element.name,
                issuedep: element.issuedep,
                imgurl: replacePath(element.sys_fld_coverpath, "0", "1"),
                doi: element.sys_fld_doi,
              };
            });
            this.pagination.total = count;
          } else {
            this.dataResource = [];
            this.pagination.total = 0;
          }
        });
      } else if (type == "video") {
        Api.getVideoList(this.pagination).then((res) => {
          const { success } = res;
          if (success) {
            const {
              data: { list, count },
            } = res;
            this.dataResource = list.map((element) => {
              return {
                name: replaceNew(element.name, this.pagination.keyword),
                title: element.name,
                source: element.source,
                imgurl: replacePath(element.sys_fld_coverpath, "0", "1"),
                doi: element.sys_fld_doi,
              };
            });
            this.pagination.total = count;
          } else {
            this.dataResource = [];
            this.pagination.total = 0;
          }
        });
      } else {
        Api.getAudioList(this.pagination).then((res) => {
          const { success } = res;
          if (success) {
            const {
              data: { list, count },
            } = res;
            this.dataResource = list.map((element) => {
              return {
                name: replaceNew(element.name, this.pagination.keyword),
                title: element.name,
                source: element.source,
                imgurl: replacePath(element.sys_fld_coverpath, "0", "1"),
                doi: element.sys_fld_doi,
              };
            });
            this.pagination.total = count;
          } else {
            this.dataResource = [];
            this.pagination.total = 0;
          }
        });
      }
    },
    // onSearch(value) {
    //   console.log(1231)
    //   //this.$router.push({ name: "searchList", params: { key: value } });
    // },
    onOpenChange(openKeys) {
      //this.openKeys=openKeys
      const latestOpenKey = openKeys.find(
        (key) => this.openKeys.indexOf(key) === -1
      );
      if (this.rootSubmenuKeys.indexOf(latestOpenKey) === -1) {
        this.openKeys = openKeys;
      } else {
        this.openKeys = latestOpenKey ? [latestOpenKey] : [];
      }
    },
    onSizeChange(current, size) {
      this.pagination.page = 1;
      this.pagination.onpage = 1;
      this.pagination.pageSize = size;
      //this.listrequest(1)
    },
    onChange(pageNumber, pageSize) {
      // 翻页时触发,分页组件绑定页数需求number类型
      this.pagination.onpage = pageNumber;
      this.pagination.page = pageNumber;
      this.pagination.pageSize = pageSize;
      this.initResources(this.selectedtype);
    },
  },
};
</script>
<style lang="less">
@import "~@/assets/css/readings.less";
</style>
<style scoped>
.title span i {
  height: 19px;
}
</style>
<style lang = "less" scoped>
.pic {
  .icon-play {
    width: 70px;
    height: 70px;
    position: absolute;
    left: 50%;
    top: 35px;
    margin-left: -35px;
    img {
      width: 70px !important;
      height: 70px !important;
    }
  }
}
.left-nav {
  ul {
    padding: 0 0;
    li {
      a {
        width: 194px !important;
        padding: 15px 30px 15px 0 !important;
        font-size: 16px !important;
        color: #5e5e5e;
        border-bottom: 1px solid #d1d1d1 !important;
        position: relative !important;
        height: auto !important;
        line-height: 22px !important;
        i {
          position: absolute;
          right: 0px;
          top: 50%;
          -webkit-transform: translateY(-50%);
          -moz-transform: translateY(-50%);
          transform: translateY(-50%);
          width: 7px;
          height: 13px;
          background: url(~@/assets/images/icon-sprites.png) no-repeat 0 -46px;
          margin-left: 20px;
        }
      }
      a.active {
        background: #ffe8e6;
        color: #d4000e;
        border-color: #d4000e;
        i {
          background: url(~@/assets/images/icon-sprites.png) no-repeat -13px -46px;
        }
      }
      a:hover {
        background: #ffe8e6;
        color: #d4000e;
        border-color: #d4000e;

        i {
          background: url(~@/assets/images/icon-sprites.png) no-repeat -13px -46px;
        }
      }
    }
    li:hover {
      background-color: #ffe8e6;
    }
    li.active {
      background-color: #ffe8e6;
    }
  }
}
</style>
