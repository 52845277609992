import { render, staticRenderFns } from "./Book.vue?vue&type=template&id=fbc2b732&scoped=true&"
import script from "./Book.vue?vue&type=script&lang=js&"
export * from "./Book.vue?vue&type=script&lang=js&"
import style0 from "./Book.vue?vue&type=style&index=0&lang=less&"
import style1 from "./Book.vue?vue&type=style&index=1&id=fbc2b732&scoped=true&lang=css&"
import style2 from "./Book.vue?vue&type=style&index=2&id=fbc2b732&lang=less&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "fbc2b732",
  null
  
)

export default component.exports