var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('div',{attrs:{"id":"main"}},[_vm._m(0),_c('div',{staticClass:"container"},[_c('div',{staticClass:"main-item clearfix book"},[_c('div',{staticClass:"left-nav fl"},[_c('h4',[_c('a',{on:{"click":_vm.changeTheme}},[_vm._v("分类导航")])]),_c('ul',_vm._l((_vm.themeList),function(item){return _c('li',{key:item.themeid,class:item.class},[_c('a',{class:item.class,attrs:{"href":"javascript:;"},on:{"click":function($event){return _vm.clicktheme(item)}}},[_vm._v(_vm._s(item.themename)),_c('i')])])}),0)]),_c('div',{staticClass:"right-con fr"},[_c('div',{staticClass:"box"},[_vm._m(1),_c('div',{staticClass:"tab"},[_vm._l((_vm.TypeList),function(item,index){return _c('a',{key:index,class:item.class,attrs:{"href":"javascript:;"},on:{"click":function($event){return _vm.TypeClick(item)}}},[_vm._v(_vm._s(item.name))])}),_c('span',[_vm._v("共"),_c('b',[_vm._v(_vm._s(_vm.pagination.total))]),_vm._v("个"+_vm._s(_vm.selectedtype == "book" ? "图书" : _vm.selectedtype == "audio" ? "听书" : "视频"))])],2),_c('div',{staticClass:"res-list res-list-3"},[_c('a-list',{attrs:{"grid":{ gutter: 30, column: 3 },"item-layout":"vertical","data-source":_vm.dataResource,"pagination":{
                  current: _vm.pagination.onpage,
                  pageSize: _vm.pagination.pageSize,
                  total: _vm.pagination.total,
                  showSizeChanger: false,
                  showLessItems: true,
                  showQuickJumper: true,
                  onChange: _vm.onChange,
                  onShowSizeChange: _vm.onSizeChange,
                }},scopedSlots:_vm._u([{key:"renderItem",fn:function(item){return (_vm.selectedtype == 'book')?_c('a-list-item',{key:item.doi,staticClass:"book",attrs:{"title":item.title},on:{"click":function($event){return _vm.goDetail('bookDetail', item.doi)}}},[_c('a-card',{staticStyle:{"width":"190px"},attrs:{"hoverable":""}},[_c('img',{attrs:{"slot":"cover","alt":"example","src":item.imgurl},slot:"cover"}),_c('a-card-meta',[_c('a',{attrs:{"slot":"title","title":item.title,"href":"javascript:;"},domProps:{"innerHTML":_vm._s(item.name)},on:{"click":function($event){return _vm.goDetail('bookDetail', item.doi)}},slot:"title"}),_c('div',{attrs:{"slot":"description"},slot:"description"},[_c('p',[_vm._v(" "+_vm._s(item.issuedep)+" ")])])])],1)],1):_c('a-list-item',{key:item.doi,staticClass:"pic",attrs:{"title":item.title},on:{"click":function($event){return _vm.goDetail(
                      _vm.selectedtype == 'audio' ? 'AudioDetail' : 'videoDetail',
                      item.doi
                    )}}},[_c('a-card',{staticClass:"pic",staticStyle:{"width":"260px"},attrs:{"hoverable":""}},[_c('img',{attrs:{"slot":"cover","alt":"example","src":item.imgurl},slot:"cover"}),(_vm.selectedtype === 'video')?_c('a',{staticClass:"icon-play",attrs:{"slot":"cover"},slot:"cover"},[_c('img',{attrs:{"src":require("@/assets/images/icon-play.png")}})]):_vm._e(),(_vm.selectedtype === 'audio')?_c('span',{attrs:{"slot":"cover"},slot:"cover"}):_vm._e(),_c('a-card-meta',[_c('a',{attrs:{"slot":"title","title":item.title,"href":"javascript:;"},domProps:{"innerHTML":_vm._s(item.name)},on:{"click":function($event){return _vm.goDetail(
                            _vm.selectedtype == 'audio'
                              ? 'AudioDetail'
                              : 'videoDetail',
                            item.doi
                          )}},slot:"title"}),_c('div',{attrs:{"slot":"description"},slot:"description"},[_c('p',[_vm._v(" "+_vm._s(item.issuedep)+" ")])])])],1)],1)}}],null,true)})],1)])])])])])])}
var staticRenderFns = [function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"banner"},[_c('img',{attrs:{"src":require("@/assets/images/banner-reading.jpg"),"alt":""}})])},function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"title"},[_c('span',[_c('i'),_vm._v("传统文化")])])}]

export { render, staticRenderFns }