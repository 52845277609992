var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('div',{attrs:{"id":"main"}},[_c('div',{staticClass:"container"},[_c('div',{staticClass:"main-item position"},[_c('a',{on:{"click":function($event){return _vm.goToView('/home/index')}}},[_vm._v("首页")]),_vm._v(" > "),_c('span',[_vm._v("活动预告")])]),_c('div',{staticClass:"main-item"},[_c('div',{staticClass:"activityclass"},[_c('div',{staticClass:"box"},[_vm._m(0),_c('a-list',{staticClass:"activitylist",attrs:{"item-layout":"vertical","data-source":_vm.dataResource,"pagination":{
                current: _vm.pagination.onpage,
                pageSize: _vm.pagination.pageSize,
                total: _vm.pagination.total,
                showSizeChanger: false,
                showLessItems: true,
                showQuickJumper: true,
                onChange: _vm.onChange,
                onShowSizeChange: _vm.onSizeChange,
              }},scopedSlots:_vm._u([{key:"renderItem",fn:function(item){return _c('a-list-item',{key:item.doi,staticClass:"list"},[_c('a',{staticClass:".list-a",attrs:{"title":item.title},domProps:{"innerHTML":_vm._s(item.name)},on:{"click":function($event){return _vm.goDetail('newsDetail',item.doi)}}}),_c('span',[_vm._v(_vm._s(item.pubdate))])])}}])})],1)])])])])])}
var staticRenderFns = [function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"title"},[_c('span',[_c('i'),_vm._v("活动预告")])])}]

export { render, staticRenderFns }